import routesPaths from './routesPaths';
import asyncComponent from './AsyncComponent';
import permissionNames from '../constants/globals/permissions';

const AsyncLogin = asyncComponent(() => import('../views/Login'));
const AsyncTwoFactorAuthentication = asyncComponent(() => import('../views/Login/TwoFactorAuthtentication'))
const AsyncEmailPasswordReset = asyncComponent(() => import('../views/PasswordReset/Email'));
const AsyncPasswordReset = asyncComponent(() => import('../views/PasswordReset/Password'));
const AsyncRegister = asyncComponent(() => import('../views/Register'));
const AsyncRegisterMarketingPartner = asyncComponent(() => import('../views/RegisterMarketingPartner'));
const AsyncEmailVerification = asyncComponent(() => import('../views/Login/EmailVerification'));
const AsyncRequest = asyncComponent(() => import('../views/NewRequest'));
const AsyncForm = asyncComponent(() => import('../views/RequestForm'));
const AsyncHome = asyncComponent(() => import('../views/Home'));
const AsyncUploadDocument = asyncComponent(() => import('../views/UploadDocument'));
const AsyncTracking = asyncComponent(() => import('../views/DealTracking'));
const AsyncTeamsManagement = asyncComponent(() => import('../views/TeamsManagement'));
const AsyncTracker = asyncComponent(() => import('../views/DealTracking/Tracker'));
const AsyncProfile = asyncComponent(() => import('../views/ProfileUpdate'));
const AsyncContact = asyncComponent(() => import('../views/Contact'));
const AsyncInvitationRegister = asyncComponent(() => import('../views/InvitationRegister'));
const AsyncFAQ = asyncComponent(() => import('../views/FAQ'));
const AsyncHermesCover = asyncComponent(() => import('../views/HermesCover'));
const AsyncCalculators = asyncComponent(() => import('../views/Calculators'));

const routes = [
  {
    path: routesPaths.login,
    component: AsyncLogin,
    type: 'public',
    title: 'Login',
  },
  {
    path: routesPaths.twoFactorAuthenticationRequest,
    component: AsyncTwoFactorAuthentication,
    type: 'public',
    title: 'Zwei-Faktor-Authentifizierung',
  },
  {
    path: routesPaths.emailPasswordReset,
    component: AsyncEmailPasswordReset,
    type: 'public',
    title: 'Passwort zurücksetzen',
  },
  {
    path: routesPaths.passwordReset,
    component: AsyncPasswordReset,
    type: 'public',
    title: 'Passwort zurücksetzen',
  },
  {
    path: routesPaths.passwordChange,
    component: AsyncPasswordReset,
    type: 'private',
    title: 'Passwort zurücksetzen',
  },
  {
    path: routesPaths.register,
    component: AsyncRegister,
    type: 'public',
    title: 'Registrierung',
  },
  {
    path: routesPaths.registerMarketingPartner,
    component: AsyncRegisterMarketingPartner,
    type: 'public',
    title: 'Anmeldung bei einem Marketingpartner',
  },
  {
    path: routesPaths.invitation,
    component: AsyncInvitationRegister,
    type: 'public',
    title: 'Invitation Register',
  },
  {
    path: routesPaths.emailVerification,
    component: AsyncEmailVerification,
    type: 'public',
    title: 'Validierung : FIB',
  },
  {
    path: routesPaths.newRequest,
    component: AsyncRequest,
    type: 'private',
    title: 'Validierung : FIB',
    name: permissionNames.addProduct,
  },
  {
    path: routesPaths.form,
    component: AsyncForm,
    type: 'private',
    title: 'FIB',
    exact: false,
    name: permissionNames.fillQuestionnaire,
  },
  {
    path: routesPaths.uploadDocument,
    component: AsyncUploadDocument,
    type: 'private',
    title: 'Dokumente Hochladen',
    exact: false,
    name: permissionNames.viewDocument,
  },
  {
    path: routesPaths.teams,
    component: AsyncTeamsManagement,
    type: 'private',
    title: 'Teamverwaltung',
    name: [
      permissionNames.viewEditor,
      permissionNames.viewExporter,
      permissionNames.viewSalesPartner]
  },
  {
    path: routesPaths.tracking,
    component: AsyncTracking,
    type: 'private',
    title: 'Deal tracking',
    name: permissionNames.viewDealTracking,
  },
  {
    path: routesPaths.trackingAdmin,
    component: AsyncTracking,
    type: 'private',
    title: 'Deal tracking',
  },
  {
    path: routesPaths.trackDeal,
    component: AsyncTracker,
    type: 'private',
    title: 'Deal tracking',
    name: permissionNames.viewDealTracking,
  },
  {
    path: routesPaths.profile,
    component: AsyncProfile,
    type: 'private',
    title: 'Profil : FIB',
  },
  {
    path: routesPaths.contact,
    component: AsyncContact,
    title: 'Kontakt & Hilfe',
    name: permissionNames.addContact,
  },
  {
    path: routesPaths.hermesCover,
    component: AsyncHermesCover,
    title: 'Hermes Cover',
  },
  {
    path: routesPaths.calculators,
    component: AsyncCalculators,
    title: 'Calculators',
  },
  {
    path: routesPaths.faq,
    component: AsyncFAQ,
    title: 'FAQ',
  },
  {
    path: routesPaths.home,
    component: AsyncHome,
    title: 'Startseite',
  },
];

export default routes;
