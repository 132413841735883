/*
  Deutsch Resource
 */

const resourceDE = {
  // Views --------

  // Login
  loginButton: 'Anmeldung',
  registerButton: 'Anmelden',
  forgotPassword: 'Passwort vergessen?',
  emailAddress: 'E-MAIL-ADRESSE',
  emailPH: 'Ihre E-Mail-Adresse',
  password: 'PASSWORT',
  passwordPH: 'Ihr Passwort',

  // RegisterLoginCard
  haveAccount: 'Haben Sie schon ein FIB-Konto?',
  haveNotAccount: 'Haben Sie noch kein FIB-Konto?',
  registerNow: 'Jetzt anmelden',
  createAccount: 'Konto erstellen',

  // Home
  editCustomerProfile: 'Kundenprofil ergänzen',
  customerProfileMessage: 'Um Anfragen zu senden, vervollständigen Sie bitte Ihr Kundenprofil.',
  customProfileMessage: 'Um Anfragen zu senden, vervollständigen Sie bitte Ihr Kundenprofil.',
  welcomeUserArea: 'Willkommen in Ihrem persönlichen Nutzerbereich!',
  createNewRequest: 'Neue Anfrage erstellen',
  productOverview: 'Weitere FIB Produkte im Überblick',
  searchLetterInfo: 'Akkreditivbestätigung suchen',
  searchLetterDescription: 'Akkreditive und Zahlungsgarantien sind häufig genutzte, gute Instrumente zur Absicherung von Zahlungsrisiken.',
  createRequest: 'Anfrage erstellen',
  lookForBuyer: 'Bestellerkredite ab 10 Mio. Euro suchen',
  lookForBuyerDescription: 'Bestellerkredite sind eine attraktive Möglichkeit für Ihre Kunden in Schwellen- und Entwicklungsländern, Investitionsgüter mittel- bis langfristig zu finanzieren.',
  searchForfaiting: 'Forfaitierung ab 10 Mio. Euro suchen',
  searchForfaitingDescription: 'Manchmal ist der Lieferantenkredit der schnellere und einfachere Weg, Ihre Kunden zu finanzieren. Damit die Liquidität trotzdem stimmt verkaufen Sie einfach Ihre Forderungen gegenüber Ihrem Kunden (Forfaitierung).',
  findCreditInsurance: 'Kreditversicherungen suchen',
  findCreditInsuranceDescription: 'Lieferungen an und die Finanzierung von Kunden im Ausland ist immer Risiken verbunden. Stellen Sie sicher, dass vereinbarten Zahlungen tatsächlich eingehen. Dabei helfen staatliche Exportkreditgarantien (Hermesdeckung) und private Kreditversicherungen.',
  exportTicket: 'Small Ticket Exportfinanzierung',
  exportTicketDescription: 'Finanzieren Sie Ihre Exportaufträge zwischen 500.000 und 10.000.000 Euro schnell und einfach mit uns.',
  addedAt: 'Hinzugefügt am: {{ date }}',
  admin: 'Administrator: {{ firstName }} {{ lastName }}',
  editor: 'Bearbeiter',
  salesPartnerCounter: 'Betreuender Vertriebspartner',
  changeCompany: 'Firma wechseln',
  affiliates: 'Verbundene Unternehmen',
  goParent: 'Go Parent',
  successInvitation: 'Ihre Einladung wurde erfolgreich',
  exporterCounter: 'Betreute Exporteure',
  invite: 'Einladen',

  // RequestTable
  ongoingInquiries: 'Laufende Anfragen',
  inquiryTitle: 'Anfragetitel',
  art: 'Art',
  status: 'Status',
  finished: 'fertig',
  lastChange: 'Letzte Bearbeitung',
  processRequest: 'Anfrage bearbeiten',

  // NEW REQUEST
  createRequestNow: 'Jetzt Anfrage erstellen',
  nextSteps: 'Nächste Schritte',
  overview: 'Übersicht',
  productSelection: 'Produktauswahl',
  requestTitle: 'Titel der Anfrage',
  requestTitlePH: 'z.B. Kundenname oder Vorgangsnummer',
  productListError: 'Sie haben Ihrer Firma keine Produkte zugewiesen. Bitte nehmen Sie Kontakt mit TranFinScout auf .',

  // PasswordReset
  emailAddressLabel: 'E-Mail-Adresse',
  resetPassword: 'Passwort zurücksetzen',
  successReset: 'Bitte überprüfen Sie Ihr Postfach, und klicken Sie in der FIB E-Mail auf den Link zum Zurücksetzen des Passworts.',
  resetPasswordTitle: 'Ihr Passwort zurücksetzten',
  sendEmailText: 'Wir haben Ihnen eine E-Mail geschickt.',
  backToLogin: 'Zurück zur Anmeldung',

  // Password
  passwordRule1: 'mindestens acht Zeichen lang sein.',
  passwordRule2: 'mindestens einen Kleinbuchstaben und einen Großbuchstaben enthalten.',
  passwordRule3: 'mindestens eine Zahl enthalten.',
  passwordRule4: 'mindestens ein Sonderzeichen.',
  enterNewPassword: 'Geben Sie Ihr neues Passwort ein.',
  saveNewPassword: 'Neues Passwort speichern',
  backToHome: 'Zurück zu Home',
  validatePassword: 'Das Passwort sollte:',
  passwordResetFailed: 'Password reset failed',
  yourNewPassword: 'Ihr neues Passwort',

  // ProfileUpdate
  successProfileUpdate: 'Aktualisierung erfolgreich',
  personalInfo: 'Persönliche Informationen',
  changePassword: 'Passwort verändern',
  exporterInfo: 'Exporteur Informationen',
  saveProfile: 'Profil speichern',
  editProfile: 'Profil bearbeiten',
  yes: 'Ja',
  no: 'Nein',
  updateExporterProfile: 'Kunden anlegen',
  updateExporterProfileMessage: 'Um Anfragen zu senden, legen Sie bitte ein Kundenprofil an.',

  // personalForm
  salute: 'Anrede',
  firstName: 'Vorname',
  firstNamePH: 'Ihre Vorname',
  lastName: 'Nachname',
  lastNamePH: 'Ihre Nachname',
  company: 'Unternehmen',
  companyPH: 'z.B. Firmenname GmbH',
  phoneNumber: 'Telefonnummer (Optional)',
  phoneNumberPH: 'z.B. +49187625375',
  phoneNumberFormat: 'Bitte geben Sie Ihre Telefonnummer im Format +49187625375 oder 0187625375 ein.',
  email: 'GESCHÄFTLICHE E-MAIL-ADRESSE',
  saluteExporter: 'Name des Ansprechpartners beim Kunden',
  exporterFunction: 'Funktion im Unternehmen',
  exporterFunctionPH: 'Funktion',

  // exporterForm
  nameCompany: 'Firmenname',
  nameCompanyPH: 'Ihr Firmenname',
  street: 'Straße / Nr.',
  streetPH: 'z.B. Goethestr. 23, 3.Etage',
  place: 'Ort',
  placePH: 'Frankfurt a.M.',
  plz: 'PLZ',
  plzPH: '60353',
  land: 'Land',
  landPH: 'Deutschland',
  industry: 'Branche',
  industryPH: 'Wählen Sie eine Branche',
  hasCompanyGroup: 'Gehört Ihr Unternehmen zu einer Unternehmensgruppe (Konzern)?',
  groupName: 'Name Ihrer Unternehmensgruppe oder Muttergesellschaft',
  groupNamePH: 'z.B. Fincite Group AG',
  coverageNumber: 'Geben Sie bitte Ihre Deckungsnehmer Nr. bei Euler Hermes an (wenn bekannt).',
  coverageNumberPH: 'z.B. Deckungsnehmer Nr.',
  employees: 'Anzahl der Mitarbeiter',
  employeesPH: '0 - 50 Personen',
  revenue: 'Umsatz im letzten vollen Geschäftsjahr',
  revenuePH: '< 10 Mio.€',
  exportQuote: 'Wie hoch ist Ihre Exportquote',
  exportQuotePH: '< 25%',
  exportOrder: 'Summe eines durchschnittlichen Exportauftrages',
  exportOrderPH: '250.000 - 500.000',
  rateExperience: 'Wie schätzen Sie Ihre Erfahrung mit Exportfinanzierungen und Deckungsinstrumenten ein?',

  // Register
  successRegister: 'Vielen Dank für Ihre Registrierung!',
  checkEmailToActive: 'Bitte checken Sie Ihre Email und aktivieren Sie Ihr Konto.',
  passwordValidation: 'Das Passwort sollte',
  woman: 'Frau',
  mr: 'Herr',
  clientType: 'Sie registrieren sich als',
  clientTypePH: 'Wählen Sie Ihr Profil',
  termsCondition: 'Bitte akzeptieren Sie die Einwilligungserklärung und Datenschutzerklärung um fortzufahren.',
  agreeTC1: 'Ich bin mit der Verarbeitung und Nutzung meiner',
  agreeTC2: 'Daten gemäß',
  termsOfUse: 'Nutzungsbedingungen',
  dataProtection: 'Datenschutzerklärung',
  agreeTC3: 'einverstanden',
  and: 'und',
  companyNotExists: 'Dieses Unternehmen ist bereits registriert. Für weitere Informationen, bitte kontaktieren Sie uns: +49 69 153259345',
  jobTitle: 'Job title (Optional)',
  jobTitlePH: 'Job Title',

  // Sales partner types
  freelancerCompany: 'Selbstständig',
  partnershipCompany: 'Personengesellschaft',
  corporationCompany: 'Kapitalgesellschaft',
  salesUpdateProfile: 'Unternehmensprofil Partner ergänzen',
  addExporterToInquiry: 'Um Anfragen zu erstellen, fügen Sie bitte Exporteure hinzu.',
  addExporter: 'Exporteur hinzufügen',

  // RequestForm

  // Control
  dealBtn: 'Dokumentenliste zum Exporteur senden',

  // Header
  sendRequestQuestion: 'Möchten Sie Ihre Anfrage absenden?',
  sendRequestExporter: 'Möchten Sie Ihre Anfrage zu Ihre Exporteur senden?',
  successSendExporterRequest: 'Ihr Anfrage wurde erfolgreich zu Ihre Exporteur gesendet.',
  successSendRequest: 'Ihr Anfrage wurde erfolgreich gesendet.',
  followRequestStatus: 'Sie können den Status Ihrer Anfrage jederzeit unter “Deal Tracking” verfolgen.',
  abort: 'Abbrechen',
  submit: 'Absenden',
  submitExporter: 'Zum Exporteur senden',
  goToDeal: 'Zu Deal Tracking',
  lastSave: 'Zuletzt gespeichert um',
  of: 'von',
  the: 'Die',
  continueLater: 'Später forsetzen',
  saveContinueLater: 'Speichern & später forsetzen',

  // Overview
  downloadPDF: 'PDF herunterladen',
  finishedRequest: 'Fertig',
  inProgressRequest: 'In Bearbeitung',
  toEdit: 'Bearbeiten',
  preview: 'Vorschau',

  // ReceivedDocuments
  documentsAvailableForExporters: 'Hier finden Sie alle Dokumente, die wir für Exporteur bereitgestellt haben',
  documentsAvailableForYou: 'Hier finden Sie alle Dokumente, die wir für Sie bereitgestellt haben',
  documentsExporterProvidesForYou: 'Hier finden Sie alle Dokumente, die Exporteur für uns bereitgestellt haben',
  documentsYouProvides: 'Hier finden Sie alle Dokumente, die Sie für uns bereitgestellt haben',

  // ReceivedDocuments
  fileName: 'Dateiname',
  step: 'Schritt',
  date: 'Datum',

  // StepForm
  incompleteFormMessage: 'Bitte beantworten Sie die markierten Fragen um Ihre Anfrage fertigzustellen und zu senden',
  toNextStep: 'Zum nächsten Schritt',
  saveGoNextStep: 'Speichern & zum nächsten Schritt',

  // StepsSidebar
  documentsReceived: 'Erhaltene Unterlagen',
  uploadDocuments: 'Hochgeladene Dokumente',
  dealStatusControl: 'Deal Status Control',

  // UploadDocument
  toUploadDocument: 'Dokumente hochladen',
  toUploadDocumentBtn: 'Neue Dokumente Hochladen',
  dealNumber: 'Deal-Nummer',
  projectName: 'Projektbezeichnung',
  lastUpdateOn: 'Letzte Aktualisierung am',
  requiredDocuments: 'Benötigte Dokumente',
  uploadCoverApplication: 'Bitte laden Sie Ihren Deckungsantrag hier hoch',
  otherDocuments: 'Sonstige Unterlagen',

  // UploadModalButton
  upload: 'Hochladen',
  dealNumberPH: 'Deal auswählen',
  saveBtn: 'Speichern',
  addExtraDocumentText: 'Dokument hinzufügen',
  documentName: 'Dokumentename',
  documentNamePH: 'Dokumentename Schreiben',
  uploadExtraFilesSuccess: 'Ihr Dokumente wurde erfolgreich hochgeladen.',

  // Authentication
  wrongPassword: 'Falsches Passwort',
  basicAuthentication: 'Basisauthentifizierung',
  authorize: 'Autorisieren',

  // Contact
  subject: 'Bitte teilen Sie uns Ihr Thema mit',
  subjectPH: 'Bitte schreiben Sie hier',
  contactYou: 'Wann können wir Sie kontaktieren?',
  contactAnytime: 'Ich bin flexibel, kontaktieren Sie mich jederzeit',
  howContactYou: 'Wie sollen wir Sie kontaktieren?',
  contactByEmail: 'Über die in meinem Konto hinterlegte Email',
  willContactYouByEmail: 'Wir kontaktieren Sie über die Email, mit der Sie sich bei FIB angemeldet haben.',
  viaAnotherEmail: 'Über eine andere Email',
  yourEmailAddress: 'Ihre Email Adresse',
  callMeAtNumber: 'Rufen Sie mich unter folgender Nummer an',
  callMeAtNumberPH: 'z.B. +49187625375',
  thanksForContactUs: 'Danke, dass sie uns kontaktiert haben. Wir werden uns kurzfristig bei Ihnen melden.',
  makeAnAppointment: 'Vereinbaren Sie einen Termin mit uns',
  appointmentDescription: 'Sie möchten mit uns sprechen? Gerne. Lassen Sie uns nachfolgend einfach wissen,\n'
    + 'wann wir Sie am besten erreichen. Sie können uns auch\n'
    + 'jederzeit gerne über die E-Mail und Telefonnummer rechts direkt kontaktieren.',
  agreementText: 'Mit dem Klicken auf “Terminanfrage senden” stimme ich zu, dass mich die FIB AG per\n'
    + 'E-Mail oder ggf. telefonisch (bei Angabe meiner Telefonnummer) zur Bearbeitung\n'
    + 'meiner Anfrage kontaktieren darf. Dies erfolgt in Übereinstimmung mit der\n'
    + 'Datenschutzerklärung der FIB Frankfurt International Bank AG (siehe Webseite und FAQs).',
  sendAppointmentRequest: 'Terminanfrage senden',
  howToFindUs: 'So finden Sie uns',
  address: 'Adresse',
  addressExample: 'Wilhelm-Leuschner Str. 27, 60329 Frankfurt',
  scheduleExample: '(Montag – Freitag 9:00 – 18:00)',
  contact: 'Kontakt',
  contactExample: '+49 (0)69',
  emailExample: 'kundenservice@fib.com',

  // Teams Management
  teamsManagement: 'Teamverwaltung',
  editCompanyProfile: 'Firmenprofil bearbeiten',
  added: 'Hinzugefügt',
  companyType: 'Unternehmen Typ',
  administrator: 'Administrator',
  editorTab: 'Bearbeiter',
  partner: 'Verbundene Unternehmen',
  supervisor: 'Betreuende Vertriebspartner',
  companies: 'Unternehmen',
  function: 'Funktion',
  registeredOn: 'Angemeldet am',
  addSalesPartner: 'Vertriebspartner hinzufügen',
  addSingleMember: 'Bearbeiter einladen',
  addGroupMember: 'Tochterunternehmen hinzufügen',
  addSalesPartnerFooter: 'Wenn die Rechte eines Vertriebspartners verändert werden sollen, kontaktieren sie uns bitte.',
  addSingleMemberFooter: 'Wenn die Rechte eines Bearbeiters verändert werden sollen, kontaktieren sie uns bitte.',
  addGroupMemberFooter: 'Wenn die Rechte eines Bearbeiters verändert werden sollen, kontaktieren sie uns bitte.',
  searchPH: 'Alle Vertriebspartner',
  remove: 'Löschen',
  editorRightsModal: 'Rechte der',
  individual: 'Bearbeiter',
  group: 'Tochterunternehmen',
  salesGroup: 'Vertriebspartner',
  sendInvitation: 'Einladung senden',
  removeUser: 'Möchten Sie {{ userName }} LÖSCHEN?',
  removeUserConfirm: '{{ userName }} wurde erfolgreich gelöscht.',
  exporterTeam: 'Exporteure',

  // DealTracking
  product: 'Produkt',
  statusUpdateOn: 'Status aktualisiert am',
  importer: 'Importeur',
  viewRequest: 'Anfrage ansehen',
  dealTracking: 'Deal Tracking',

  // Deal Filter
  filter: 'Filter',
  dealNumberLabel: 'Deal Number',
  dealNumberPlaceholder: 'Deal Number',
  productLabel: 'Produkt',
  productPlaceholder: 'Produkt',
  statusLabel: 'Status',
  statusPlaceholder: 'Status',
  importerLabel: 'Importeur',
  importerPlaceholder: 'Importeur',
  clear: 'Clear',
  clearAllFilters: 'Clear all filters',

  // InvitationRegister
  exporter: 'Exporteur',
  salesPartner: 'VertriebsPartner',
  teamMember: 'Bearbeiter',

  // Components -------

  // UserProfile
  successProfileSaved: 'Danke! Ihr Kundenprofil wurde erfolgreich gespeichert.',
  clientProfile: 'Kundenprofil',
  stepCounter: 'Schritte <1>{{ step }}</1>/{{ maxStep }}',
  next: 'Fortfahren',
  additionalEntryDescription: 'Sie können weitere Bearbeiter und verbundene Unternehmen auch jederzeit in der Teamverwaltung hinzufügen.',
  inviteEditor: 'Weitere Bearbeiter einladen',
  editorRights: 'Rechte der Bearbeiter',
  addEmail: 'E-MAIL-ADRESSE HINZUFÜGEN',
  addCompanyTitle: 'Verbundene Unternehmen hinzufügen',
  companyNameLabel: 'NAMEN DES UNTERNEHMENS',
  singleCompany: 'Einzelnes Unternehmen',
  groupCompany: 'Obergesellschaft (Gruppe/Konzern)',
  parentCompany: 'Tochterunternehmen (Gruppe/Konzern)',
  companyTypeLabel: 'Art des Unternehmens',
  parentCompanyLabel: 'Name Ihrer Unternehmensgruppe oder Muttergesellschaft',
  emailError: 'Ungültiges Format "{{ errorValue }}"',
  commonEmailError: 'Ungültiges Format',
  successEmails: 'The members were added to this company succesfully.',
  add: 'ADD',
  emailBackendError: 'Diese E-Mail {{ errorValue }} ist nicht erlaubt.',

  // Rights Editor
  dashboardAccess: 'Zugriff auf das Dashboard',
  addAccess: 'Neu Anfragen erstellen',
  sendAccess: 'Anfragen absenden',
  documentAccess: 'Documente hochladen',
  singleCompanyType: 'Bearbeiter können',
  dashboardAccessDescriptionSingle: 'Bearbeiter können auf das Dashboard zugreifen und alle Informationen ansehen.',
  addAccessDescriptionSingle: 'Bearbeiter können eine neue Anfrage erstellen.',
  sendAccessDescriptionSingle: 'Bearbeiter können eine Anfrage an FIB senden.',
  documentAccessDescriptionSingle: 'Bearbeiter können Dokumente hochladen.',
  dashboardAccessDescriptionGroup: 'Das Vertriebspartner kann auf das Dashboard zugreifen und alle Informationen ansehen.',
  addAccessDescriptionGroup: 'Das Vertriebspartner kann eine neue Anfrage erstellen.',
  sendAccessDescriptionGroup: 'Das Vertriebspartner kann eine Anfrage an FIB senden.',
  documentAccessDescriptionGroup: 'Das Vertriebspartner kann Dokumente hochladen.',
  dashboardAccessDescriptionParent: 'Bearbeiter können auf das Dashboard zugreifen und alle Informationen ansehen.',
  addAccessDescriptionParent: 'Bearbeiter können kann eine neue Anfrage erstellen.',
  sendAccessDescriptionParent: 'Bearbeiter können kann eine Anfrage an FIB senden.',
  documentAccessDescriptionParent: 'Bearbeiter können kann Dokumente hochladen.',

  logout: 'Abmelden',
  user: 'Benutzer',

  // StepPanel -> finalStep
  hasBeenConfirmed: 'wurde von Ihnen bestätigt',

  // Sider
  // constants
  contactHelp: 'Kontakt & Hilfe',

  // SiderDrawer
  weAreHereForYou: 'WIR SIND FÜR SIE DA',
  weAreHereForYouExample: '+49 (0) 69 153249345',

  // Components --------

  // CompanySelector
  companySelectorName: 'Mein Unternehmen:',

  // DashboardCardSimple
  viewTracking: 'Tracking ansehen',

  // DocumentDownloadModal
  pdfTitle: 'Herunterladen',

  // DocumentUploadForm
  sendedDocumentFeedback: 'Das Dokument wurde gesendet.',
  declineDocumentFeedback: 'Das Angebot wurde abgelehnt.',
  requestDocumentFeedback: 'Die Dokumentenliste wurde mit dem Exporteur geteilt',
  fileFeedback: 'Gesendete Dokumente:',
  requestFileFeedback: 'Dokumentenliste',
  fileNameRequired: 'Beschreibung der Eingabedatei',
  uploadDocumentText: 'Dokumente hochladen',
  addDocumentText: 'Hinzufügen',
  documentRequestButton: 'Dokumentenliste senden',
  documentPlaceholder: 'Dokumente auswählen',
  documentDefaultOption: '--',
  fileRequired: 'Diese Datei wird benötigt',
  dragFile: 'Ziehen Sie diese Datei hierhin oder laden diese hoch',
  uploadFileButton: 'Datei hochladen',
  uploadedDocuments: 'Hochgeladene Dokumente',
  uploadFileTitle: 'Dokumente hochladen',
  dateText: 'Datum : ',
  uploadedFiles: 'Hochgeladene Dateien',
  fileUploaded: 'Datei hochgeladen',

  // Footer
  privacy: 'Datenschutz',
  imprint: 'Impressum',

  // Header
  backToTracking: 'Zurück zu Trackingübersicht',
  requestHelp: 'Hilfe anfordern an',
  headerPhone: '+49 (0) 69 153249345',

  // Horizontal Time Line
  timeLineTooltip: 'Bitte laden Sie die fehlenden Unterlagen hoch',
  timeLineDeal: 'Deal Tracking',
  goUploadDocument: 'Zum Dokumente hochladen',

  // Item Table
  downloadDocument: 'Dokumente herunterladen',

  // Mini Progress Bar
  uploadText: 'Hochgeladen',
  uploadTextPercentage: '% hochgeladen',

  // FAQ
  faqTitle: 'FAQs',
  faqSubtitle: 'Haben Sie Fragen? Lassen Sie uns Ihnen helfen, die Antwort hier zu finden!',
  keywordLabel: 'Suche nach Schlüsselwörtern',
  keywordPlaceholder: 'Suche nach Schlüsselwörtern…',
  selectKeyword: 'oder Schlüsselwort auswählen',

  // Hermes Cover Pre-Check
  hermesCover: 'Vorab-Check Hermesdeckung',

  // Calculator
  calculator: 'Tools und Rechner',
  calculatorsTitle: 'Was kostet eine Hermesdeckung (für Laufzeiten > 2 Jahre bei halbjährlicher Tilgung)?',
  calculatorTooltip: 'Bitte verwenden Sie diesen Rechner, um das Hermes-Entgelt für Forderungsdeckungen mit einer Finanzierungslaufzeit von  <0> mindestens </0> zwei Jahren zu berechnen.',
  calculatorInterestTooltip: 'Bitte verwenden Sie diesen Rechner, um das Hermes-Entgelt für Forderungsdeckungen mit einer Finanzierungslaufzeit von  <0> mindestens </0> zwei Jahren und halbjährlicher Tilgung zu berechnen.',
  contractValue: 'Auftragswert',
  deposit: 'Anzahlung',
  depositFooter: 'mindestens 15% erforderlich',
  notPermitted: 'Nicht zulässig',
  validInputPercentage: 'Bitte geben Sie mindestens 15% ein.',
  creditAmount: 'Kreditbetrag',
  categoryLand: 'Länderkategorie',
  categoryLandTooltip: ' Die Länderkategorie ist die von der OECD festgelegte Länderrisikoklasse eines Landes. Die Länderkategorien wird für das gewählte Land auf Basis der aktuellen geltenden Risikokategorien automatisch ermittelt.',
  gradeTitle: 'Ihre Bonitätseinschätzung des Käufers',
  gradeLabel: 'Käuferkategorie',
  gradeTooltip: 'Die Käuferkategorie ist die Ratingnote, die Euler Hermes für den Käufer vergibt. Die Käuferkategorie ist auch vom Unternehmensumfeld im jeweiligen Land abhängig. Eine durchschnittliche Bonität in Deutschland kann in einem anderen Land gut oder sogar sehr gut sein.\n'
    + 'Als grobe Orientierung können Sie, bezogen auf das jeweilige Land, folgendes annehmen: <0 /> <0 />'
    + 'CC0 = exzellente Bonität (dieses Ergebnis ist sehr unwahrscheinlich) <0 />'
    + 'CC1 = sehr gute Bonität <0 />'
    + 'CC2 = gute Bonität <0 />'
    + 'CC3 = durchschnittliche Bonität <0 />'
    + 'CC4 = ausreichende Monität <0 />'
    + 'CC5 = schwache Bonität <0 /> <0 />'
    + 'Achtung: nicht jede Käuferkategorie ist bei jeder Länderkategorie zulässig. Ab Länderkategorie 5 sind nicht mehr alle Käuferkategorien deckungsfähig. Ist eine Kombination aus Länderkategorie und Käuferkategorie nicht zulässig, erhalten Sie das Ergebnis nicht zulässig.',
  riskPeriod: 'Risikolaufzeit für Deckungskosten',
  startDelivery: 'Lieferbeginn bis vollständige Lieferung',
  monaten: 'Monaten',
  years: 'Jahren',
  financingTerm: 'Finanzierungslaufzeit',
  monthsTooltip: 'Für die Berechnung der Risikolaufzeit wird zur Finanzierungslaufzeit die sogenannte Vorlaufzeit addiert. Die Vorlaufzeit ist der Zeitraum, in dem Euler Hermes bereits Deckung gewährt, aber noch nicht vollständig geliefert bzw. geleistet ist. <0 /> <0 />'
    + 'Diesen Zeitraum können Sie hier in Monaten eingeben. Da die Auswirkung auf die Prämie relativ gering ist, gibt auch die reine Finanzierungslaufzeit schon einen guten Anhaltswert für die Prämie.',
  calculate: 'Berechnen',
  tableColumn: 'Berechnete Kosten der Hermesdeckung',
  tableColumn2: 'Beträge in EUR',
  creditCoverage: 'Lieferantendkreditdeckung mit 15% Selbstbehalt (Standard)',
  financialCreditCoverage: 'Finanzkreditdeckung (z.B. für Bestellerkredite, Entgeld wird mit finanziert)',
  suppliedCreditCoverage: 'Lieferantenkreditdeckung mit auf 5% reduziertem Selbstbehalt',
  onlyBuyerCredit: 'Kreditbetrag bei Mitfinanzierung der Deckungskosten (z.B. Bestellerkredit)',
  tableColumnTooltip: 'Für die Übernahme von Hermes-Deckungen ist ein Entgelt zu entrichten, dessen Höhe im Wesentlichen von der Risikolaufzeit, der Länderkategorie sowie der Käuferkategorie abhängt. <0 /> <0 />'
    + 'Bei der Lieferantendkreditdeckung wirkt sich auch die Höhe des Selbstbehaltes aus. Stantard ist ein Selbstbehalt von 15%. Das ist oft ungünstig, '
    + 'besonders, wenn Sie eine Forfaitierung suchen. Beantragen Sie besser die Absenkung des Selbstbehalts auf 5%. Dadurch erhöht sich das Entgeld zward um 10%,'
    + 'Sie können aber i.d.R. Ihre Bilanz dann mit einer Forfaitierung entlasten. <0 /> <0 />'
    + 'Bei einem Bestellerkredit ist es üblich, den Entgeltbetrag zum Finanzkreditdeckung bereits berücksichtigt.',

  excelent: 'Exzellent',
  good: 'Sehr Gut',
  nice: 'Gut',
  average: 'Durchschnittlich',
  enough: 'Ausreichend',
  weak: 'Schwach',

  hermesCalculator: 'Prämienrechner Hermesdeckung',
  interestCalculator: 'Rechner kostendeckender Käuferzins',
  interestTitle: 'Kostendeckender Käuferzins bei Forfaitierung eines Lieferantenkredits (ab 2 Jahre Finanzierungslaufzeit)',
  leadTime: 'Vorlaufzeit',
  riskPeriodTitle: 'Risikolaufzeit',
  riskPeriodTitleTooltip: 'Die Risikolaufzeit ist kann länger sein als die reine Finanzierungslaufzeit, weil die Exportkreditgarantie schon ab Beginn der Lieferung greift. Gibt es mehrere Lieferungen oder wird unter dem Exportvertrag auch montiert, ist die Risikolaufzeit regelmäßig länger als die Finanzierungslaufzeit. Die Risikolaufzeit beeinflusst die Höhe der Deckungskosten.',
  interestResultsTitle: 'Prämie für Lieferantenkreditdeckung mit auf 5% reduziertem Selbstbehalt:',
  required: 'Pflichtfeld',
  buyerInterest: 'Kostendeckender Käuferzins',
  gainInterest: 'Zinsgewinn / Zinsverlust Exporteuer',
  discountRate: 'Bitte geben Sie hier den erwarteten Diskontsatz ein (fragen Sie uns gerne nach einer Indikation).',
  incomeInterest: 'Hier berechnen Sie, welchen Zinsertrag oder - aufwand Sie bei anderen Käuferzinssätzen haben.',
  bankInterest: 'Zinskosten Bank p.a.',
  disagio: 'Disagio (Bearbeitungsgebühr)',
  disagioInterest: 'Gebühren (Einmalig)',
  interestRate: 'Zinssatz an Lieferant',

  disclaimerHermes: 'Alle unsere Rechner sind nach bestem Wissen und Gewissen erstellt und werden fortlaufend aktualisiert. Dennoch dienen Sie nur als Orientierungshilfe, Ergebnisse sind in keiner Weise verbindlich und FIB übernimmt keinerlei Haftung für die Richtigkeit, Aktualität und Fehlerfreiheit. Alle Prämien und Kosten von Hermesdeckungen werden verbindlich ausschließlich von der Euler Hermes AG, Exportkreditgarantien der Bundesrepublik Deutschland, festgelegt.',
  fabricationCalculator: 'Fabrikationsrisikodeckung',
  rechnerCalculator: 'Rechner Antrags- und Ausfertigungsgebühr Hermesdeckung',
  calculatorFabricationTooltip: 'Mit der Fabrikationsrisikodeckung können Exporteure die im Zusammenhang mit einem Ausfuhrgeschäft anfallenden Produktionskosten (Selbstkosten, diese müssen im Schadenfall nachgewiesen werden) absichern. <0 /> <0 />'
    + 'Die Absicherung der Risiken ab Versand ist über eine zusätzliche Forderungsdeckung (Lieferantenkreditdeckung / Finanzkreditdeckung) möglich. <0 /><0 />'
    + 'Für die Übernahme der Fabrikationsrisikodeckung ist ein Entgelt zu entrichten, dessen Höhe im Wesentlichen von den Selbstkosten, der Dauer der Fabrikationszeit, dem Umfang der gedeckten Risiken (Deckungsumfang) sowie der Länderkategorie abhängt.',
  fabricationTitle: 'Entgeltrechner für Fabrikationsrisikodeckungen',
  initDate: 'Fertigungsbeginn',
  endDate: 'Fertigungsende',
  fabricationYear: 'Fabrikationszeit',
  coverScope: 'Deckumsumfang',
  economicRisk: 'Wirstchaftliche und politische Risiken',
  restrictionRisk: 'Beschränkung auf politische Risiken',
  cost: 'Selbstkostenbetrag',
  coverResultTitle: 'Entgelt für Fabrikationsrisikodeckung',
  coverLandTooltip: 'Wenn der Versand in ein Drittland erfolgt, wird entweder die Länderkategorie des Bestellerlandes oder die des Drittlandes zugrunde gelegt. <0 /><0 />'
  + 'Die Länderkategorie des Drittlandes kommt zur Anwendung, wenn diese ungünstiger ist als die des Bestellerlandes.',
  notPossible: 'Nicht möglich',
  enterData: 'Bitte Daten eingeben',
  applicationTitle: 'Euler Hermes-Gebührenrechner',
  applicationTitleTooltip: 'Hier sehen Sie auf einen Blick, was ein Antrag auf Hermesdeckung (Antragsgebühr) und die Ausfertigung der Deckungsurkunde (Ausfertigungsgebühr) kosten.<0 /><0 />'
    + 'Kann ein Antrag auf Forderungsdeckung (Lieferantenkreditdeckung) digital über Click&Cover Export gestellt werden, entfallen diese Gebühren derzeit. <0 /><0 />'
    + 'Für eine Fabrikationsrisikodeckung fallen keine separaten Gebühren an, wenn diese zusammen mit einer Forderungsdeckung beantragt wird.<0 /><0 />'
    + 'Die Antragsgebühr ist unabhängig davon zu bezahlen, ob eine Deckungszusage erteilt oder versagt wird.',
  coverType: 'Deckungsart',
  coverTypeTooltip: '<1>Lieferantenkreditdeckung</1> <0 />'
    + 'Ermöglicht deutschen Exportunternehmen Forderungen aus einem Ausfuhrgeschäft abzusichern <0 /><0 />'
    + '<1>Finanzkreditdeckung</1><0 />'
    + 'Ermöglicht Banken Darlehensforderungen abzusichern, die aus der Finanzierung eines deutschen Exportgeschäftes resultieren <0 /> <0 />'
    + '<1>Fabrikationsrisikodeckung</1><0 />'
    + 'Ermöglicht deutschen Exporteuren fürs Ausfuhrgeschäft anfallende Produktionskosten abzusichern<0 /><0 />'
    + '<1>Ausfuhrpauschalgewährleistung</ 1><0 />'
    + 'Absicherung kurzfristiger Forderungen (Zahlungsziel bis 12 Monate) dt. Exporteure, die wiederholt mehrere Besteller in unterschiedlichen Ländern beliefern<0 /><0 />'
    + '<1>Vertragsgarantiedeckung</ 1><0 />'
    + 'Schutz vor Verlusten aus politisch bedingter oder widerrechtlicher Ziehung der vertraglichen Garantie',
  supplierCreditCover: 'Lieferantenkreditdeckung',
  buyerCreditCover: 'Finanzkreditdeckung',
  riskCoverFabrication: 'Isolierte oder mit einer APG kombinierte Fabrikationsrisikodeckung',
  isolatedContractBondCover: 'Isolierte Vertragsgarantiedeckung',
  orderValue: 'Auftragswert',
  loanAmount: 'Darlehensbetrag',
  feeIncurred: 'Anfallende Gebühr bei Euler Hermes',
  applicationFee: 'Antragsgebühr',
  executionFee: 'Ausfertigungsgebühr',
  costPrice: 'Selbstkosten',
  warrantyAmount: 'Garantiebetrag',
  notAvailable: 'Nicht verfügbar',
  //TwoFactorAuthentication
  twoFactorAuthenticationTitle: 'Zwei-Faktor-Authentifizierung',
  twoFactorAuthenticationLabel: '2FA code',
  twoFactorAuthenticationPlaceHolder:'Den Code eingeben',
  send2FACode: 'Code senden',
  twoFactorAuthenticationText: 'Wir haben gerade einen Bestätigungscode an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang und verwenden Sie den Code, um den Authentifizierungsprozess abzuschließen.\n',
  twoFactorAuthenticationText2: 'Wenn Sie den Code nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner oder klicken Sie auf "Code erneut senden".',
  twoFactorAuthenticationSuccessTitle: 'Zwei-Faktor-Authentifizierung erfolgreich',
  verify2FACodeSuccess: 'Ihr Konto wurde erfolgreich verifiziert.',
  send2FACodeSuccess: 'Der Code wurde erfolgreich gesendet.',
  resendCode: 'Code erneut senden',
  resendCodeExpires: 'Der Code läuft in : ',
  verify2FACodeError: 'Der Code ist ungültig.',
  //buyer calculator
  buyerCreditCalculator: 'Kalkulator Bestellerkredit',
  buyerCreditTitle: 'Kalkulator Bestellerkredit (2 - 7 Jahre Finanzierungslaufzeit)',
  buyerCreditTooltip: 'Alle Kalkulationen sind unverbindlich und dienen lediglich einer ersten Orientierung.',
  creditOrderValue: 'Auftragswert',
  initialCreditPayment: 'Anzahlung',
  creditLoanAmount: 'Kreditbetrag',
  deliveryCountry: 'Lieferland',
  countryCategory: 'Länderkategorie',
  financialCreditCoverageFee: 'Entgeld Finanzkreditdeckung (für Bestellerkredite, Entgeld wird mit finanziert) = Kreditbetrag 2 in EUR',
  validInputFinancingTerm: 'Bitte geben Sie zwischen 2 und 7 ',
  buyerCreditMargins1: 'Zinsmarge über 6-Monats Euribor\n',
  buyerCreditMargins2: 'Aktueller 6-Monats Euribor\n',
  buyerCreditMargins3: 'Kundenzins, wenn heute valutiert würde',
  oneTimeManagementFee: 'Management Fee einmalig in EUR',
  totalLoanAmount:'Kreditbetrag insgesamt (Kreditbetrag 1 + Kreditbetrag 2)',
  creditFeeCoverage: 'Entgeld Finanzkreditdeckung (für Bestellerkredite, Entgeld wird mit finanziert)',
  //table
  buyerCreditTableTitle: 'Zins- und Tilgungsplan',
  buyerCreditTableColumn1: 'Periode',
  buyerCreditTableColumn1Subtitle: 'Halbjahre am Periodenende',
  buyerCreditTableColumn2: 'Restschuld',
  buyerCreditTableColumn3: 'Tilgung Raten',
  buyerCreditTableColumn4: 'Zinsen',
  buyerCreditTableColumn4Subtitle: 'Zinssatz p.a',
  buyerCreditTableColumn5: 'Zahlungen',
  disclaimerBuyersCalculator: 'Alle unsere Rechner sind nach bestem Wissen und Gewissen erstellt und werden fortlaufend aktualisiert. Dennoch dienen Sie nur als Orientierungshilfe, Ergebnisse sind in keiner Weise verbindlich und FIB übernimmt keinerlei Haftung für die Richtigkeit, Aktualität und Fehlerfreiheit. Alle Prämien und Kosten von Hermesdeckungen werden verbindlich ausschließlich von der Euler Hermes AG, Exportkreditgarantien der Bundesrepublik Deutschland, festgelegt.',
  // Constants ---------
  pleaseFillField: 'Bitte füllen Sie dieses Feld aus',
  validEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  noBlankSpaces: 'Keine Leerzeichen',
  wrongEmailPassword: 'Falsche E-Mail-Adresse oder falsches Passwort!',
  onlyNumber: 'Nur Zahlen',
  onlyLetterAndCharacter: 'Nur Buchstaben und Sonderzeichen',
  fileType: 'Laden Sie ein korrektes Dateiformat hoch',
  notAllowed: 'Something went wrong.',
};

export default resourceDE;
