const routesPaths = {
  home: '/',
  emailPasswordReset: '/password/email',
  passwordReset: '/password/reset/:uid/:token',
  passwordChange: '/password/change',
  emailVerification: '/email/verification/:uid/:token',
  register: '/register',
  registerMarketingPartner: '/register/:partner',
  newRequest: '/request',
  form: '/form',
  login: '/login',
  twoFactorAuthenticationRequest: '/login/2FA',
  uploadDocument: '/upload/document',
  tracking: '/tracking',
  trackingAdmin: '/form/:dealId/steps/tracking',
  trackDeal: '/tracking/:dealId',
  teams: '/teams',
  profile: '/profile',
  contact: '/contact',
  invitation: '/invitation/register/:uid/:token',
  calculators: '/calculators',
  hermesCover: '/check',
  faq: '/faq',
};

export default routesPaths;
