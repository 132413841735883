import { APP_LANGUAGE } from '../../constants/globals/settings';
import {
  getRefreshTokenName,
  setLoginTokens,
  isUndefined,
  getAuthTokenName,
} from '../../utils/tools';

const refreshMutation = `
mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    payload
    refreshToken
  }
}`;

const errorToRefresh = ['Signature has expired'];

/**
 * Function to refresh token when token is expired
 * @param {String} uri
 * @param {Object} options
 */
const customFetch = (uri, options) => {
  let refreshingPromise = null;
  const token = localStorage.getItem(getAuthTokenName()) ? `JWT ${localStorage.getItem(getAuthTokenName())}` : '';
  options.headers.authorization = token;
  options.headers['Accept-Language'] = localStorage.getItem(APP_LANGUAGE) || 'de';

  const initialRequest = fetch(uri, options);

  return initialRequest.then((response) => (response.json())).then((json) => {
    if (json && json.errors && json.errors[0] && errorToRefresh.includes(json.errors[0].message)) {
      if (!refreshingPromise) {
        refreshingPromise = fetch(uri, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: refreshMutation,
            variables: {
              refreshToken: localStorage.getItem(getRefreshTokenName()),
            },
          }),
        }).then((refreshTokenResponse) => {
          if (refreshTokenResponse.ok) {
            return refreshTokenResponse.json().then((refreshJSON) => {
              if (!isUndefined(refreshJSON.errors) && refreshJSON.errors.length) {
                localStorage.clear();

                return refreshJSON;
              }
              const { data } = refreshJSON;
              const { refreshToken, token } = data.refreshToken;
              setLoginTokens(token, refreshToken);

              return token;
            });
          }
          localStorage.clear();
        });
      }

      return refreshingPromise.then((newAccessToken) => {
        refreshingPromise = null;

        if (typeof newAccessToken === 'string') {
          options.headers.authorization = `JWT ${newAccessToken}`;
          const newBody = JSON.parse(options.body);
          const { token } = newBody.variables;
          if (!isUndefined(token)) {
            newBody.variables.token = newAccessToken;
            options.body = JSON.stringify(newBody);
          }
        }

        return fetch(uri, options);
      });
    }
    const result = {};
    result.ok = true;
    result.json = () => new Promise(((resolve) => {
      resolve(json);
    }));
    result.text = () => new Promise(((resolve) => {
      resolve(JSON.stringify(json));
    }));

    return result;
  });
};

export default customFetch;
