import { ReactComponent as DashboardIcon } from '../../media/icons/icon_dashboard.svg';
import { ReactComponent as AddIcon } from '../../media/icons/icon_add.svg';
import { ReactComponent as SendIcon } from '../../media/icons/icon_inbox.svg';
import { ReactComponent as UploadIcon } from '../../media/icons/icon_upload.svg';
import { isNull } from '../../utils/tools';
import permissionNames from './permissions';

export const AUTH_TOKEN = 'FBI-(bqh@%!str-2v@i@84pk&e$or_if-m2xo!q'; /** global: AUTH_TOKEN */
export const REFRESH_TOKEN = 'FBI-rr-(!str-2v@i@84pk&e$or_if-m2xq'; /** global: REFRESH_TOKEN */
export const API_URL = typeof process.env.REACT_APP_DEV_API_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_API_URL : false; /** global: API_URL */
export const DATE_FORMAT = 'DD.MM.YYYY'; /** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const MONTHLY_FORMAT = 'YYYY-MM'; /** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const FILE_DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH.mm.ss ';
export const TIME_FORMAT_GERMANY = 'HH:mm [Uhr] ';
export const FILE_SECOND_FORMAT = 'HH:mm:ss';
export const FILE_MAX_SIZE = 10;
export const COMPANY = '2v@i@84pk&es';

export const FORMAT_FILE_ACCEPTED = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
];

export const BACKEND_FRONTEND_LANGUAGES = {
  de: 'DE',
  'en-us': 'EN',
};

export const APP_LANGUAGE = 'language';
export const TERMS_AND_CONDITIONS_LINK = 'https://trafinscout.com/nutzungsbedingungen';
export const DATA_PROTECTION_LINK = 'https://trafinscout.com/datenschutz';

export const COMPANY_TYPES_IDS = {
  single: 1,
  group: 2,
  parent: 3,
};

export const COMPANY_TYPES = (t, companyType = false) => [
  {
    value: 1,
    display_name: t('singleCompany'),
    disabled: companyType !== false && !isNull(companyType) ? companyType !== 1 : false,
  },
  {
    value: 2,
    display_name: t('groupCompany'),
    disabled: false,
  },
  {
    value: 3,
    display_name: t('parentCompany'),
    disabled: false,
  },
];

export const SALES_TYPES = (t) => [
  {
    value: 1,
    display_name: t('freelancerCompany'),
  },
  {
    value: 2,
    display_name: t('partnershipCompany'),
  },
  {
    value: 3,
    display_name: t('corporationCompany'),
  },
];

export const USER_TYPES = {
  exporter: 1,
  salesPartner: 2,
  teamMember: 3,
};

export const USER_TYPES_LIST = (t) => [
  {
    id: 1,
    name: t('exporter'),
    permissionName: permissionNames.addSubsidiaryCompany,
  },
  {
    id: 2,
    name: t('salesPartner'),
    permissionName: permissionNames.addSalesPartner,
  },
  {
    id: 3,
    name: t('teamMember'),
    permissionName: permissionNames.addEditor,
  },
];

export const SALES_PARTNER_TYPES_LIST = (t) => [
  {
    id: 1,
    name: t('teamMember'),
    permissionName: permissionNames.addEditor,
  },
  {
    id: 2,
    name: t('exporterCounter'),
    permissionName: permissionNames.addExporter,
  },
];

/** Keep this USER_TYPES_OBJECTS sync with USER_TYPES_LIST */

export const USER_TYPES_OBJECTS = (t) => ({
  1: {
    name: t('exporter'),
  },
  2: {
    name: t('salesPartner'),
  },
  3: {
    name: t('teamMember'),
  },
});

const rightsByCompanyType = (t) => [
  {
    dashboard: t('dashboardAccessDescriptionSingle'),
    addAccess: t('addAccessDescriptionSingle'),
    sendAccess: t('sendAccessDescriptionSingle'),
    documentAccess: t('documentAccessDescriptionSingle'),
  },
  {
    dashboard: t('dashboardAccessDescriptionParent'),
    addAccess: t('addAccessDescriptionParent'),
    sendAccess: t('sendAccessDescriptionParent'),
    documentAccess: t('documentAccessDescriptionParent'),
  },
  {
    dashboard: t('dashboardAccessDescriptionGroup'),
    addAccess: t('addAccessDescriptionGroup'),
    sendAccess: t('sendAccessDescriptionGroup'),
    documentAccess: t('documentAccessDescriptionGroup'),
  },
];

export const editorRights = (t, companyType) => [
  {
    icon: DashboardIcon,
    title: t('dashboardAccess'),
    description: rightsByCompanyType(t)[companyType].dashboard,
  },
  {
    icon: AddIcon,
    title: t('addAccess'),
    description: rightsByCompanyType(t)[companyType].addAccess,
  },
  {
    icon: SendIcon,
    title: t('sendAccess'),
    description: rightsByCompanyType(t)[companyType].sendAccess,
  },
  {
    icon: UploadIcon,
    title: t('documentAccess'),
    description: rightsByCompanyType(t)[companyType].documentAccess,
  },
];
